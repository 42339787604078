<template>
  <div class="row justify-content-center">
    <home-header></home-header>
    <vs-prompt
      title=""
      @accept="deleteActivity"
      accept-text="ลบ"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="deletePrompt"
    >
      <div class="con-exemple-prompt">ลบข้อมูลนี้?</div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title="เรียกใช้บริการ"
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @cancel="(formModel.dttm = ''), (formModel.desc = '')"
      @accept="acceptAddRequest"
      @close="close"
      :is-valid="validAddForm"
      :active.sync="activePromptAddForm"
    >
      <div class="con-exemple-prompt">
        <ul style="margin-top: 20px">
          <label
            style="margin-left: 10px"
            class="vs-component con-vs-radio vs-radio-primary"
            v-for="(item, index) in user_cloud_config.request_help_topic"
            :key="index"
          >
            <input
              v-model="formModel.request_type"
              name="request_type"
              :value="item"
              type="radio"
              class="vs-radio--input"
            />
            <span class="vs-radio">
              <span
                class="vs-radio--borde"
                style="border: 2px solid rgb(200, 200, 200)"
              ></span>
              <span class="vs-radio--circle"></span>
            </span>
            <span class="vs-radio--label">{{ item }}</span>
          </label>
        </ul>
        <vs-input
          v-if="
            formModel.request_type == 'เรียกรถแท๊กซี่' ||
              formModel.request_type == 'เรียกมอเตอร์ไซด์' ||
              formModel.request_type == 'เรียกแท๊กซี่' ||
              formModel.request_type == 'เรียกรถมอเตอร์ไซด์'
          "
          icon="mode_edit"
          placeholder="ปลายทาง"
          v-model="formModel.topic"
        />
        <vs-textarea
          icon="mode_edit"
          placeholder="รายละเอียดเพิ่มเติม"
          v-model="formModel.desc"
          style="margin-top: 6px"
        />
        <vs-alert :active="!validAddForm" color="danger" icon="new_releases">
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>
    <div v-if="enable">
      <vs-list>
        <vs-list-item class="k-nav-bar" title="รายการเรียกใช้บริการ" subtitle="">
          <vs-button
            @click="activePromptAddForm = true"
            color="primary"
            type="filled"
            >เรียกใช้บริการ</vs-button
          >
        </vs-list-item>
      </vs-list>
      <vs-card style="margin-top: 10px">
        <div>
          <vs-table stripe :data="items">
            <template slot="thead" style="margin-top: 100px">
              <vs-th> ลบ </vs-th>
              <vs-th> วันที่ </vs-th>
              <vs-th> หัวข้อ </vs-th>
              <vs-th> รายละเอียด </vs-th>
              <vs-th> รับข้อมูล </vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    type="flat"
                    @click="openDeletePromt(data[indextr].key)"
                    color="primary"
                    icon="delete"
                  ></vs-button>
                </vs-td>
                <vs-td :data="data[indextr].create_date">
                  {{
                    new Date(data[indextr].create_date.seconds * 1000)
                      | moment("dddd, Do MMMM YYYY")
                  }}
                </vs-td>
                <vs-td :data="data[indextr].request_type">
                  {{ data[indextr].request_type }}
                </vs-td>
                <vs-td :data="data[indextr].desc">
                  <span v-if="data[indextr].topic.length > 0"></span>
                  {{ data[indextr].topic }} {{ data[indextr].desc }}
                </vs-td>
                <vs-td :data="data[indextr].readable">
                  <span
                    v-if="
                      data[indextr].readable == false ||
                        data[indextr].readable == null
                    "
                    >รอดำเนินการ</span
                  >
                  <span v-if="data[indextr].readable == true">ดำเนินการแล้ว</span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
              <br />
              <paginate
                v-model="page"
                :page-count="total"
                :page-range="3"
                :margin-pages="2"
                :click-handler="inputData"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
              >
              </paginate>
            </div>
        </div>
      </vs-card>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import * as lang from "vuejs-datepicker/src/locale";
import HomeHeader from "@/layouts/components/custom/HomeHeader.vue";
import Paginate from "vuejs-paginate";
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
console.log("lang", lang);

Vue.use(VueCookies);
Vue.component("home-header", HomeHeader);
Vue.component("paginate", Paginate);
Vue.component("the-advertising", TheAdvertising);
moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  data() {
    return {
      deletePrompt: false,
      items: [],
      // user_cloud_config: [],
      hNumber: "",
      customerName: "",
      activePromptAddForm: false,
      formModel: {
        request_type: "",
        topic: "",
        desc: "",
        readable: false
      },
      // pagination
      total: 0, // จำนวนหน้า
      limit: 10,
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      // lastItem: null,

      user_cloud_config: {},
      enable: null,
      listAdvertising: {},
      show_per_page: 10,
    };
  },
  computed: {
    validAddForm() {
      console.log(this.formModel);

      if (
        (this.formModel.request_type == "เรียกรถแท๊กซี่" ||
          this.formModel.request_type == "เรียกมอเตอร์ไซด์" ||
          this.formModel.request_type == "เรียกแท๊กซี่" ||
          this.formModel.request_type == "เรียกรถมอเตอร์ไซด์") &&
        this.formModel.topic.length < 1
      ) {
        return false;
      }

      if (
        this.formModel.request_type == null ||
        this.formModel.request_type == ""
      ) {
        return false;
      }

      if (
        !(
          this.formModel.request_type == "เรียกแท๊กซี่" ||
          this.formModel.request_type == "เรียกรถมอเตอร์ไซด์" ||
          this.formModel.request_type == "เรียกรถแท๊กซี่" ||
          this.formModel.request_type == "เรียกมอเตอร์ไซด์"
        )
      ) {
        return (this.formModel.topic = "");
      }

      return true;
    }
  },
  methods: {
    documentPath() {
      const customerName = $cookies.get("customer");
      const _documentPath = "customer/" + customerName + "/request";
      // console.log("documentPath=" + _ducumentPath);
      return _documentPath;
    },
    isHavePrefixAdddress() {
      return !(
        this.houseInfo.prefix_address == null ||
        this.houseInfo.prefix_address == ""
      );
    },
    getDbWithCondition_x() {
      return db.collection(this.documentPath())
        .where("house_no", "==", this.hNumber + "")
        .where("type", "==", "help")
    },

    deleteActivity() {
      db.collection(this.documentPath())
        .doc(this.deleteId)
        .delete()
        .then(() => {
          console.log("Document deleted!");
          this.getTotalRow();

        })
        .catch(error => {
          console.error(error);
        });
    },

    openDeletePromt(id) {
      this.deleteId = id;
      this.deletePrompt = true;
    },

    acceptAddRequest(color) {
      this.formModel.house_no = this.hNumber;
      this.formModel.type = "help";
      this.formModel.dttm = new Date();
      this.formModel.create_date = new Date();
      this.formModel.readable = false;

      if (this.isHavePrefixAdddress()) {
        this.formModel.prefix_address = this.houseInfo.prefix_address;
      } else {
        this.formModel.prefix_address = "";
      }

      const uid = moment(new Date()).unix() + "";

      console.log("[acceptAddRequest] this.formModel=", this.formModel);

      event.preventDefault();
      db.collection(this.documentPath())
        .doc(uid)
        .set(this.formModel)
        .then(() => {
          this.formModel = {
            request_type: "",
            topic: "",
            desc: ""
          };

          this.getTotalRow();

          this.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว"
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    close() {
      this.formModel = {
        request_type: "",
        topic: "",
        desc: "",
        readable: false
      };
    },

    async getTotalRow_x() {
      let totalCustomer = await this.getDbWithCondition()
        .get()
        .then(document => document.docs.length);
      this.total = Math.ceil(totalCustomer / this.limit);
      this.totalItems = totalCustomer;
    },

    async getLastItem() {
      console.log("[[getLastItem]]");

      var _limit = (this.page - 1) * this.show_per_page;
      console.log("this.page=" + this.page);
      console.log("this.show_per_page=" + this.show_per_page);
      console.log("_limit=" + _limit);

      if (_limit == 0) return null;

      this.hNumber = $cookies.get("home");
            console.log("hNumber=" + this.hNumber);

      var reference = null;

      if (this.isHavePrefixAdddress()) {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("type", "==", "help")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");

      } else {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("type", "==", "help")
          .where("prefix_address", "==","");

          // .where("prefix_address", "==",null);
      }

      reference = reference.orderBy("dttm", "desc");
      reference = reference.limit(_limit);

      const snapshot = await reference.get();
      console.log("snapshot.docs.length=" + snapshot.docs.length);

      const last = snapshot.docs[snapshot.docs.length - 1];
      return last;
    },

    async getTotalRow() {
      let totalCustomer = await this.getDbWithCondition(true, null)
        .get()
        .then((document) => document.docs.length);

      console.log("totalCustomer=" + totalCustomer);

      this.total = Math.ceil(totalCustomer / this.show_per_page);
      this.totalItems = totalCustomer;
    },

    getDbWithCondition(isForCountAll = false, lastItem) {
      this.hNumber = $cookies.get("home");

      console.log("[[getDbWithCondition]] this.hNumber="+this.hNumber);

      var reference = null;

      if (this.isHavePrefixAdddress()) {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("type", "==", "help")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");
      } else {
        reference = db
          .collection(this.documentPath())
          .where("house_no", "==", this.hNumber + "")
          .where("type", "==", "help")
           .where("prefix_address", "==","");
      }

      if (isForCountAll == true) {
        return reference;
      }

      reference = reference.orderBy("dttm", "desc");

      console.log("this.show_per_page=" + this.show_per_page);

      if (lastItem != null) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      } else {
        reference = reference.limit(this.show_per_page);
      }

      return reference;
    },

  async listItems({ page = 1 }) {
      console.log("page=" + page);

      this.isLoad = true;

      this.getTotalRow();

      this.getLastItem().then((lastItem) => {
        console.log("lastItem", lastItem);

        this.getDbWithCondition(false, lastItem).onSnapshot(
          (snapshotChange) => {

            this.items = [];

            snapshotChange.forEach((doc) => {

              this.items.push({
             key: doc.id,
              create_date: doc.data().create_date,
              request_type: doc.data().request_type,
              topic: doc.data().topic,
              desc: doc.data().desc,
              readable: doc.data().readable
              });

              this.isLoad = false;
            });
          }
        );
      });
      // console.log("last=",last);
    },

    listItems_x() {
      this.getTotalRow();
      this.getDbWithCondition()
        .orderBy("create_date", "desc")
        .onSnapshot(snapshotChange => {
          this.items = [];
          snapshotChange.forEach(doc => {
            this.items.push({
              key: doc.id,
              create_date: doc.data().create_date,
              request_type: doc.data().request_type,
              topic: doc.data().topic,
              desc: doc.data().desc,
              readable: doc.data().readable
            });
            this.isLoad = false;
          });
        });
    },
    previousData: function () {
      this.listItems({ page: this.page });
    },
    nextData: function () {
      this.listItems({ page: this.page });
    },
    inputData: function (value) {
      this.page = value;
      this.listItems({ page: this.page });
    },
    fetchHome_x: async function({ page = 0, limit = 10 }) {
      this.isLoad = true;
      let indexOf = limit * page;
      this.limit = limit;
      this.getDbWithCondition()
        .orderBy("create_date", "desc")
        .get()
        .then(document => {
          this.lastItem = document.docs[indexOf];
          this.getDbWithCondition()
            .orderBy("create_date", "desc")
            .startAt(this.lastItem)
            .limit(this.limit)
            .onSnapshot(snapshotChange => {
              this.items = [];
              snapshotChange.forEach(doc => {
                this.items.push({
                  key: doc.id,
                  create_date: doc.data().create_date,
                  request_type: doc.data().request_type,
                  topic: doc.data().topic,
                  desc: doc.data().desc,
                  readable: doc.data().readable
                });
                this.isLoad = false;
              });
            });
        });
    }
  },
  components: {},

  created() {
    this.hNumber = $cookies.get("home");
    this.customerName = $cookies.get("customer");
    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);
    if (
      typeof this.houseInfo.active !== "undefined" ||
      this.houseInfo.active !== null
    ) {
      console.log("this.houseInfo.active => ", this.houseInfo.active);
      if (this.houseInfo.active === false) {
        this.$vs.notify({
          title: "<center>VCar Park</center>",
          text: "ไม่มี สิทธิ์ใช้งานส่วนเสริม",
          color: "danger",
          position: "top-center"
        });
        setTimeout(() => this.$router.push({ path: "/pages/error-403" }), 2000);
      }
    }

    this.listItems({page: this.page});
    this.user_cloud_config = $cookies.get("user_cloud_config");
    // console.log("this.user_cloud_config", this.user_cloud_config);
    this.enable = this.user_cloud_config.enable_request_help;
    this.listAdvertising = ListAdvertising.page_request_help;
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
</style>
